/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #6b7280;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #010112;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1D4ED8;
}
